import { Link, useLocation, useNavigate } from "react-router-dom"
import { useEffect, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEnvelope, faSpinner, faArrowLeftLong } from "@fortawesome/free-solid-svg-icons"
import Api from "../../../services/api"
import moment from "moment"
import { useAuthContext } from "../../../context/AuthContext"
// import { ToastContainer, toast } from "react-toastify"
// import "react-toastify/dist/ReactToastify.css"

const RegisterVerifyOTP = () => {
    const { showToast } = useAuthContext()
    const location = useLocation()
    const name = location.state?.name
    const email = location.state?.email
    const exp = location.state?.exp

    const [expiryTime, setExpiryTime] = useState(exp)
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(expiryTime))
    const [intervalId, setIntervalId] = useState(null)
    const [formData, setFormData] = useState({
        otp: ''
    })
    const [formErrors, setFormErrors] = useState({
        otp: '',
    });
    const [loading, setLoading] = useState(false)
    const [resend, setResend] = useState(false)
    const navigateTo = useNavigate()

    const handleChange = (e) => {
        const { name, value } = e.target;
        const numberValue = value.replace(/[^0-9]/g, '')
        setFormData({
          ...formData,
          [name]: numberValue
        });
    };

    const verifyProcess = async (e) => {
        e.preventDefault()
        // setErrorStatus(false)

        if(validasiForm()){

            try {
                setLoading(true)
                const payloads = {
                    email: email,
                    otp: formData.otp
                }
                const response = await Api.post(`signup/verifyOTP`, payloads)

                // console.log(response)

                if(response.data.status !== true){
                    // toast.error(response.data.message, {
                    //     position: 'top-center'
                    // })
                    showToast(response.data.message, 'error')
                } else {
                    showToast('OTP Terverifikasi, silahkan login', 'success')
                    navigateTo('/signin')
                    // toast.success('OTP terverifikasi', {
                    //     position: 'top-center',
                    //     onClose: () => {
                    //         navigateTo('/')
                    //     }
                    // })
                }

            } catch (error) {
                showToast('Terjadi kesalahan pada server', 'error')
                // toast.error('Terjadi kesalahan pada server', {
                //     position: 'top-center'
                // })
                console.log(error)
            } finally {
                setLoading(false)
            }
        } 
    }

    const validasiForm = () => {
        let valid = true
        const errors = {}

        if(!formData.otp.trim()){
            errors.otp = 'OTP tidak boleh kosong'
            valid = false
        }

        setFormErrors(errors)
        return valid
    }

    useEffect(() => {
        if(intervalId){
            clearInterval(intervalId)
        }

        const id = setInterval(() => {
            const current = moment().format('YYYY-MM-DD HH:mm:ss')
            if(expiryTime <= current){
                clearInterval(intervalId)
                setResend(true)
            } else {
                setTimeLeft(calculateTimeLeft(expiryTime));
            }
            
        }, 1000);

        setIntervalId(id)
    
        return () => clearInterval(intervalId);
    }, [expiryTime])

    const formatTime = (minutes, seconds) => {
        return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    };

    function calculateTimeLeft(expiryTime) {
        // console.log(expiryTime)
        const now = moment();
        const endTime = moment(expiryTime);
        const duration = moment.duration(endTime.diff(now));

        // Return 0 if time is up
        if (duration.asSeconds() <= 0) {
            return { minutes: 0, seconds: 0 };
        }

        const minutes = Math.floor(duration.asMinutes());
        const seconds = Math.floor(duration.asSeconds() % 60);

        return { minutes, seconds };
    }

    const resendEmail = async () => {
        try {
            const payloads = {
                name:name,
                email:email
            }
            const newExpiry = moment().add(1, 'minutes').format('YYYY-MM-DD HH:mm:ss')
            const response = await Api.post(`signup/resendOTP`, payloads)

            if(response.data.status !== true){
                // toast.error(response.data.message, {
                //     position: 'top-center'
                // })
                showToast(response.data.message, 'error')
            } else {
                setExpiryTime(newExpiry)
                setTimeLeft(calculateTimeLeft(newExpiry))
                setResend(false)
            }

            
            // console.log(response)
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <div className="login-page">
            {/* <ToastContainer theme="colored" hideProgressBar={false} closeOnClick  /> */}
            <div className="header-page">
                <Link to="/"><FontAwesomeIcon icon={faArrowLeftLong} className="icon" /></Link>
                <div className="w-100 text-center"><h2>Verifikasi OTP</h2></div>
            </div>
            <div className="login-form">
                <form onSubmit={verifyProcess}>
                    <div className="form-group text-center">
                        <label>Masukkan 6 digit kode OTP yang telah dikirim ke <strong>{ email }</strong>, silahkan cek email kamu</label>
                        <input type="text" inputMode="numeric" maxLength={6} className={`form-control shadow-none text-center ${formErrors.otp ? 'is-invalid': ''}`} name='otp' value={formData.otp} onChange={handleChange}/>
                        {formErrors.otp && (<div className="invalid-feedback">{formErrors.otp}</div>) }
                        <div className="mt-3 small">
                        <p className="mb-1" style={{ fontSize: '14px'}}>Tidak menerima kode OTP ? Silahkan klik tombol dibawah</p>
                        <p className="m-0">
                            {resend ? (<><button type="button" className="btn btn-success btn-sm" onClick={resendEmail}><FontAwesomeIcon icon={faEnvelope}/> Kirim ulang kode</button></>) : (<><button type="button" className="btn btn-success btn-sm"><FontAwesomeIcon icon={faEnvelope} /> Tunggu { formatTime(timeLeft.minutes, timeLeft.seconds) }</button></>)}
                            </p>
                        </div>
                    </div>
                    <button type="submit" className="btn btn-primary btn-block py-2" disabled={loading}>{loading ? (<><FontAwesomeIcon icon={faSpinner} spin /> Loading...</>) : 'Verifikasi' }</button>
                </form>
                <div className="text-center mb-3">
                    
                </div>
            </div>
        </div>
    )
}

export default RegisterVerifyOTP