import { Link, useNavigate } from "react-router-dom"
import { useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeftLong, faSpinner, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons"
import Api from "../../services/api"
// import { ToastContainer, toast } from "react-toastify"
// import "react-toastify/dist/ReactToastify.css"
import { useAuthContext } from "../../context/AuthContext"

const Login = () => {
    const { loginAction, showToast } = useAuthContext()
    const [formData, setFormData] = useState({
        email: '',
        password: ''
    })
    const [formErrors, setFormErrors] = useState({
        email: '',
        password: ''
    });
    const [loading, setLoading] = useState(false)
    const navigateTo = useNavigate()
    const [showPassword, setShowPassword] = useState(false)

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value
        });
    };

    const loginProcess = async (e) => {
        e.preventDefault()
        // setErrorStatus(false)

        if(validasiForm()){

            try {
                setLoading(true)
                const payloads = {
                    email: formData.email,
                    password: formData.password
                }
                const response = await Api.post(`signin`, payloads)

                // console.log(response)

                if(response.data.status !== true){
                    showToast(response.data.message, 'error')
                } else {
                    showToast('Selamat Datang ' + response.data.data.user, 'success')
                    loginAction(response.data.data)
                    navigateTo('/home')
                }

            } catch (error) {
                // toast.error('Terjadi kesalahan pada server', {
                //     position: 'top-center'
                // })
                console.log(error)
            } finally {
                setLoading(false)
            }
        } 
    }

    const validasiForm = () => {
        let valid = true
        const errors = {}

        if(!formData.email.trim()){
            errors.email = 'Email tidak boleh kosong'
            valid = false
        }

        if(!formData.password.trim()){
            errors.password = 'Password tidak boleh kosong'
            valid = false
        }

        setFormErrors(errors)
        return valid
    }
    return (
        <div className="login-page">
            {/* <ToastContainer theme="colored" hideProgressBar={false} closeOnClick  /> */}
            <div className="header-page">
                <Link to="/"><FontAwesomeIcon icon={faArrowLeftLong} className="icon" /></Link>
                <div className="w-100 text-center"><h2>Login</h2></div>
            </div>
            <div className="login-form">
                <p>Silahkan login untuk masuk ke aplikasi</p>
                <form onSubmit={loginProcess}>
                    <div className="form-group">
                        <label>Email </label>
                        <input type="text" className={`form-control shadow-none ${formErrors.email ? 'is-invalid': ''}`} name='email' value={formData.email} onChange={handleChange}/>
                        {formErrors.email && (<div className="invalid-feedback">{formErrors.email}</div>) }
                    </div>
                    <div className="form-group">
                        <div className="d-flex justify-content-between">
                            <label>Password</label>
                            <Link to="/forgot-password">Lupa Password ?</Link>
                        </div>

                        <div className="password-wrapper">
                            <input type={`${showPassword ? 'text' : 'password'}`} className={`form-control shadow-none ${formErrors.password ? 'is-invalid': ''}`} name='password' value={formData.password} onChange={handleChange} />
                            <i onClick={() => setShowPassword(!showPassword)}><FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} /></i>
                            {formErrors.password && (<div className="invalid-feedback">{formErrors.password}</div>) }
                        </div>
                    </div>
                    {/* <div className="text-right mb-3">
                        <p className="m-0"><Link to="/forgot-password">Lupa Password ?</Link></p>
                    </div> */}
                    <button type="submit" className="btn btn-primary btn-block py-2" disabled={loading}>{loading ? (<><FontAwesomeIcon icon={faSpinner} spin /> Loading...</>) : 'Login' }</button>
                </form>
                {/* <div className="text-center mb-3">
                Belum punya akun ? Daftar <Link to="/register">di sini</Link>
                </div> */}
            </div>
        </div>
    )
}

export default Login