import { Outlet, useLocation, useNavigate } from "react-router-dom"
import Api from "../services/api"
import '../assets/css/styles.css'
import { useEffect } from "react"
import { useAuthContext } from "../context/AuthContext"

const MainTemplate = () => {
    const { token, logoutAction } = useAuthContext()
    const location = useLocation()
    const navigateTo = useNavigate()

    const verifyToken = async () => {
        try {
            const payloads = {
                token: token
            }
            const response = await Api.post(`verify`, payloads, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            // console.log('verify token ', response)
            if(response.data.status !== true){
                logoutAction()
                alert(response.data.message)
                navigateTo('/')
            }
        } catch (error) {
            logoutAction()
            console.log(error)
            navigateTo('/')
        }
    }

    useEffect(() => {
        verifyToken()
    }, [location.pathname])

    return (
        <div className="main-wrapper">
            <Outlet />
        </div>
    )
}

export default MainTemplate