import { createContext, useCallback, useContext, useState } from 'react'
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

export const AuthContext = createContext()

export const AuthContextProvider = ({ children }) => {
    const [token, setToken] = useState(localStorage.getItem('token'))
    const [user, setUser] = useState(localStorage.getItem('user'))

    const loginAction = (payloads) => {
        localStorage.setItem('token', payloads.token)
        localStorage.setItem('user', payloads.user)
        setToken(payloads.token)
        setUser(payloads.user)
    }

    const logoutAction = () => {
        setToken(null)
        setUser(null)
        localStorage.removeItem('token')
        localStorage.removeItem('user')
    }

    const updateProfile = (payloads) => {
        setUser(payloads.name)
        localStorage.setItem('user', payloads.name)
    }

    const showToast = useCallback((message, type = 'info') => {
        // console.log(type)
        switch(type){
            case 'success':
                toast.success(message, {
                    position: 'top-center'
                });
                break;
            case 'error':
                toast.error(message, {
                    position: 'top-center'
                });
                break;
            case 'info':
                toast.info(message, {
                    position: 'top-center'
                });
                break;
            case 'warn':
                toast.warn(message, {
                    position: 'top-center'
                });
                break;
            default:
                toast(message, {
                    position: 'top-center'
                });
        }

    }, [])

    return (
        <AuthContext.Provider value={{ token, user, loginAction, logoutAction, updateProfile, showToast }}>
            { children }
            <ToastContainer theme="colored" hideProgressBar={false}  />
        </AuthContext.Provider>
    )
}

export const useAuthContext = () => {
    return useContext(AuthContext)
}