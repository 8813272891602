import { Link, useLocation, useNavigate } from "react-router-dom"
import { useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSpinner, faArrowLeftLong, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons"
import Api from "../../../services/api"
import { useAuthContext } from "../../../context/AuthContext"
// import { ToastContainer, toast } from "react-toastify"
// import "react-toastify/dist/ReactToastify.css"

const ResetPassword = () => {
    const { showToast } = useAuthContext()
    const location = useLocation()
    const email = location.state?.email
    const [formData, setFormData] = useState({
        password1: '',
        password2: ''
    })
    const [formErrors, setFormErrors] = useState({
        password1: '',
        password2: ''
    });
    const [loading, setLoading] = useState(false)
    const navigateTo = useNavigate()
    const [showPassword1, setShowPassword1] = useState(false)
    const [showPassword2, setShowPassword2] = useState(false)

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value
        });
    };

    const resetProcess = async (e) => {
        e.preventDefault()
        
        let valid = true
        const errors = {}

        if(!formData.password1.trim()){
            errors.password1 = 'Password Baru tidak boleh kosong'
            valid = false
        }

        if(!formData.password2.trim()){
            errors.password2 = 'Konfirmasi Password Baru tidak boleh kosong'
            valid = false
        }

        if(formData.password1 && formData.password2){
            if(formData.password2 !== formData.password1){
                errors.password2 = 'Konfirmasi Password Baru tidak sesuai'
                valid = false
            }
        }

        setFormErrors(errors)

        if(valid){
            setLoading(true)
            try {
                const payloads = {
                    email: email,
                    password: formData.password1
                }
                const response = await Api.post(`forgotPassword/reset`, payloads)
                // console.log(response)

                if(response.data.status !== true){
                    // toast.error(response.data.message, {
                    //     position: 'top-center'
                    // })
                    showToast(response.data.message, 'error')
                } else {
                    showToast(response.data.message, 'success')
                    navigateTo('/signin')
                    // toast.success(response.data.message, {
                    //     position: 'top-center',
                    //     onClose: () => {
                    //         navigateTo('/signin')
                    //     }
                    // })
                }
            } catch (error) {
                showToast('Terjadi kesalahan pada server', 'error')
                // toast.error('Terjadi kesalahan pada server', {
                //     position: 'top-center'
                // })
                console.log(error)
            } finally {
                setLoading(false)
            }
        }
    }

   
    return (
        <div className="login-page">
            {/* <ToastContainer theme="colored" hideProgressBar={false} closeOnClick  /> */}
            <div className="header-page">
                <Link to="/forgot-password"><FontAwesomeIcon icon={faArrowLeftLong} className="icon" /></Link>
                <div className="w-100 text-center"><h2>Reset Password</h2></div>
            </div>
            <div className="login-form">
                <p>Silahkan ubah password kamu</p>
                <form onSubmit={resetProcess}>
                    <div className="form-group">
                        <label>Password Baru</label>
                        <div className="password-wrapper">
                            <input type={`${showPassword1 ? 'text' : 'password'}`} className={`form-control ${formErrors.password1 ? 'is-invalid': ''}`} name="password1" onChange={handleChange} value={formData.password1}/>
                            <i onClick={() => setShowPassword1(!showPassword1)}><FontAwesomeIcon icon={showPassword1 ? faEyeSlash : faEye} /></i>
                            {formErrors.password1 && (<div className="invalid-feedback">{formErrors.password1}</div>) }
                        </div>
                    </div>
                    <div className="form-group">
                        <label>Konfirmasi Password Baru</label>
                        <div className="password-wrapper">
                            <input type={`${showPassword2 ? 'text' : 'password'}`} className={`form-control ${formErrors.password2 ? 'is-invalid': ''}`} name="password2" onChange={handleChange} value={formData.password2}/>
                            <i onClick={() => setShowPassword2(!showPassword2)}><FontAwesomeIcon icon={showPassword2 ? faEyeSlash : faEye} /></i>
                            {formErrors.password2 && (<div className="invalid-feedback">{formErrors.password2}</div>) }
                        </div>
                    </div>
                    <button type="submit" className="btn btn-primary btn-block py-2" disabled={loading}>{loading ? (<><FontAwesomeIcon icon={faSpinner} spin /> Loading...</>) : 'Reset Password' }</button>
                </form>
            </div>
        </div>
    )
}

export default ResetPassword