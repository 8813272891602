import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons"
import { Link, useLocation } from "react-router-dom"
import { useEffect, useState } from "react"
import IncomeTransactionList from "../components/IncomeTransactionList"
import ExpenseTransactionList from "../components/ExpenseTransactionList"
import ReportTransaction from "../components/ReportTransaction"

const MoneyManager = () => {
    const [currenComponent, setCurrentComponent] = useState('expense')
    const location = useLocation()
    const compo = location.state?.compo

    useEffect(() => {
        // console.log('compo ', compo)
        if(compo){
            setCurrentComponent(compo)
        }
    }, [])
    return (
        <div className="transaction-page section-page">
            <div className="transaction-page-header">
                <div className="section-page-header">
                    <Link to="/home"><FontAwesomeIcon icon={faArrowLeft} /></Link>
                    <div className="text-center w-100"><h2>Catatan Keuangan</h2></div>
                </div>
                <div className="section-page-hero">
                    <ul>
                        <li className={`${currenComponent === 'expense' ? 'active':''}`} onClick={() => setCurrentComponent('expense')}>Pengeluaran</li>
                        <li className={`${currenComponent === 'income' ? 'active':''}`} onClick={() => setCurrentComponent('income')}>Pemasukan</li>
                        <li className={`${currenComponent === 'report' ? 'active':''}`} onClick={() => setCurrentComponent('report')}>Laporan</li>
                    </ul>
                </div>
            </div>
            <div className="section-page-body">
                {currenComponent === 'expense' ? (
                    <ExpenseTransactionList />
                ) : currenComponent === 'income' ? (
                    <IncomeTransactionList />
                ) : (
                    <ReportTransaction />
                )}
                
            </div>           
            
        </div>
    )
}

export default MoneyManager