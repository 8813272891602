import { Link } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft, faFilter } from "@fortawesome/free-solid-svg-icons"
import Api from "../services/api"
import { useEffect, useState } from "react"
import moment from "moment"
import Empty from "../assets/images/paper.png"
import { useAuthContext } from "../context/AuthContext"

const SearchTransaction = () => {
    const { token } = useAuthContext()
    const [formData, setFormData] = useState({
        tanggal1: '',
        tanggal2: '',
        type: 'expense',
        category: '',
        notes: '',
        category_name: ''
    })
    const [formErrors, setFormErrors] = useState({})
    const [categories, setCategories] = useState([])
    // const [type, setType] = useState('expense')
    const [transactionList, setTransactionList] = useState([])
    const [transactionLoader, setTransactionLoader] = useState(false)
    const [isOpen, setIsOpen] = useState(false)
    const [showCategoriesBox, setShowCategoriesBox] = useState(false) 

    const getCategories = async () => {
        setFormData({...formData, category_name: '', category: ''})
        try {
            const response = await Api.get(`categories/${formData.type}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            // console.log(response)
            setCategories(response.data.data)
        } catch (error) {
            console.log(error)
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        setFormData({
            ...formData,
            [name]: value
        })
    }

    const validasiForm = () => {
        let valid = true
        const errors = {}

        if(!formData.tanggal1.trim()){
            errors.tanggal1 = 'Tanggal Mulai tidak boleh kosong'
            valid = false
        }

        if(!formData.tanggal2.trim()){
            errors.tanggal2 = 'Tanggal Selesai tidak boleh kosong'
            valid = false
        }

        setFormErrors(errors)
        return valid
    }

    const searchProcess = async (e) => {
        e.preventDefault()
        if(validasiForm()){
            try {
                setTransactionLoader(true)
                const payloads = formData
                const response = await Api.post(`transactions/search`, payloads, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
                // console.log(response)
                setTransactionList(response.data.data)
                setIsOpen(false)
            } catch (error) {
                console.log(error)
            } finally {
                setTransactionLoader(false)
            }
        }
    }

    const handleChange3 = (id, name) => {
        setFormData({
            ...formData,
            category: id,
            category_name: name
        });
        setShowCategoriesBox(false)
      };

    useEffect(() => {
        getCategories()
    }, [formData.type])

    return (
        <div className="transaction-page section-page">
            <div className="transaction-page-header">
                <div className="section-page-header">
                    <Link to="/home"><FontAwesomeIcon icon={faArrowLeft} /></Link>
                    <div className="text-center w-100"><h2>Cari Transaksi</h2></div>
                </div>
            </div>
            <div className="section-page-body">
                <div className="bg-white p-3 mb-2">
                    <button type="button" className="btn btn-outline-dark btn-sm" onClick={() => setIsOpen(true)}><FontAwesomeIcon icon={faFilter} /> Filter</button>
               </div>

               {transactionLoader ? (
                <div>
                    <div className="mb-2 bg-white p-2">
                        <div className="animated-background mb-2" style={{ height: '25px'}}></div>
                        <div className="d-flex justify-content-between">
                            <div className="animated-background mb-2" style={{ height: '25px', width: '45%'}}></div>
                            <div className="animated-background mb-2" style={{ height: '25x', width: '45%'}}></div>
                        </div>
                    </div>
                    <div className="mb-2 bg-white p-2">
                        <div className="animated-background mb-2" style={{ height: '25px'}}></div>
                        <div className="d-flex justify-content-between">
                            <div className="animated-background mb-2" style={{ height: '25px', width: '45%'}}></div>
                            <div className="animated-background mb-2" style={{ height: '25x', width: '45%'}}></div>
                        </div>
                    </div>
                    <div className="mb-2 bg-white p-2">
                        <div className="animated-background mb-2" style={{ height: '25px'}}></div>
                        <div className="d-flex justify-content-between">
                            <div className="animated-background mb-2" style={{ height: '25px', width: '45%'}}></div>
                            <div className="animated-background mb-2" style={{ height: '25x', width: '45%'}}></div>
                        </div>
                    </div>
                    <div className="mb-2 bg-white p-2">
                        <div className="animated-background mb-2" style={{ height: '25px'}}></div>
                        <div className="d-flex justify-content-between">
                            <div className="animated-background mb-2" style={{ height: '25px', width: '45%'}}></div>
                            <div className="animated-background mb-2" style={{ height: '25x', width: '45%'}}></div>
                        </div>
                    </div>
                    <div className="mb-2 bg-white p-2">
                        <div className="animated-background mb-2" style={{ height: '25px'}}></div>
                        <div className="d-flex justify-content-between">
                            <div className="animated-background mb-2" style={{ height: '25px', width: '45%'}}></div>
                            <div className="animated-background mb-2" style={{ height: '25x', width: '45%'}}></div>
                        </div>
                    </div>
                </div>
            ) : (
                <>
                    {Object.entries(transactionList).length > 0 ? (
                        <>
                        {Object.entries(transactionList).map(([date, items]) => (
                            <div className="transaction-list" key={date}>
                                <div className="transaction-list-header">
                                    <h3>{moment(date).format('dddd, DD MMM YYYY')}</h3> 
                                    {/* <h3>Rp{new Intl.NumberFormat('ID-id').format(items.reduce((sum, item) => sum + item.nominal, 0))}</h3>  */}
                                </div>
                                <ul>
                                    {items.map(item => (
                                        <li key={item.id}>
                                            <div>
                                                <label>{item.category_name}</label>
                                                <p>{item.notes}</p>
                                            </div>
                                            <div>
                                                <label className={`${item.type === 'Income' ? 'text-success': 'text-danger'}`}>{item.type === 'Expense' && '-'}Rp{new Intl.NumberFormat('ID-in').format(item.nominal)}</label>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        ))}
                        </>
                    ) : (
                        <div className="empty-box">
                            <img src={Empty} className="empty-image" />
                            <h4>Tidak ada data</h4>
                            <p>Untuk saat ini data yang kamu cari tidak ada</p>
                        </div>
                    )}
                    
                </>
            )}
                
            </div>           
            
            {/* filter container */}
            <div className={`mobile-popup ${isOpen ? 'open':''}`}>
                <div className="mobile-popup-header">
                    <button type="button" className="btn bg-transparent border-0" onClick={() => setIsOpen(false)}><FontAwesomeIcon icon={faArrowLeft} /></button>
                    <div className="text-center w-100"><h2>Filter</h2></div>
                </div>
                <div className="mobile-popup-body">
                    <form onSubmit={searchProcess}>
                        <div className="form-group">
                            <label>Tanggal Mulai</label>
                            <input type="date" className={`form-control ${formErrors.tanggal1 ? 'is-invalid': ''}`} name="tanggal1" value={formData.tanggal1} onChange={handleChange} />
                            {formErrors.tanggal1 && (<div className="invalid-feedback">{formErrors.tanggal1}</div>) }
                        </div>
                        <div className="form-group">
                            <label>Tanggal Selesai</label>
                            <input type="date" className={`form-control ${formErrors.tanggal2 ? 'is-invalid': ''}`} name="tanggal2" value={formData.tanggal2} onChange={handleChange} />
                            {formErrors.tanggal2 && (<div className="invalid-feedback">{formErrors.tanggal2}</div>) }
                        </div>
                        <div className="form-group">
                            <label>Tipe Transaksi</label>
                            <div>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" name="type" id="inlineRadio1" value="expense" onChange={handleChange} checked={formData.type === 'expense'} />
                                    <label className="form-check-label" htmlFor="inlineRadio1">Pengeluaran</label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" name="type" id="inlineRadio2" value="income" onChange={handleChange} checked={formData.type === 'income'} />
                                    <label className="form-check-label" htmlFor="inlineRadio2">Pemasukan</label>
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <label>Kategori</label>
                            {/* <select className="form-control" name="category" value={formData.category} onChange={handleChange}>
                                <option value="">Pilih</option>
                                {categories.length > 0 && categories.map((i, idx) => (
                                    <option value={i.id} key={idx}>{i.name}</option>
                                ))}
                            </select> */}
                            <input type="text" className={`form-control bg-transparent`} onClick={() => setShowCategoriesBox(true)} name="categogry_name" value={formData.category_name} readOnly />
                        </div>
                        <div className="form-group">
                            <label>Note</label>
                            <input type="text" className="form-control" name="notes" value={formData.notes} onChange={handleChange} />
                        </div>
                        <button type="submit" className="btn btn-primary btn-block">Cari</button>
                    </form>
                </div>
            </div>

            <div className={`mobile-popup ${showCategoriesBox ? 'open':''}`}>
                <div className="mobile-popup-header">
                    <button type="button" className="btn bg-transparent border-0" onClick={() => setShowCategoriesBox(false)}><FontAwesomeIcon icon={faArrowLeft} /></button>
                    <div className="text-center w-100"><h2>Kategori</h2></div>
                </div>
                <div className="mobile-popup-body">
                    <ul className="category-list">
                        {categories.length > 0 ? (
                            <>
                                {categories.map((i, idx) => (
                                    <li key={idx} onClick={() => handleChange3(i.id, i.name)}>{i.name}</li>
                                ))}
                            </>
                        ) : (
                            <>
                                <div className="alert alert-danger">Tidak ada data</div>
                            </>
                        )}
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default SearchTransaction