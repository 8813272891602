import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons"
import { Link, useNavigate, useParams } from "react-router-dom"
import ExpenseDetail from "../components/ExpenseDetail"
import IncomeDetail from "../components/IncomeDetail"
import Api from "../services/api"
import { useAuthContext } from "../context/AuthContext"

const DetailTransaction = () => {   
    let params = useParams()
    const { token, showToast } = useAuthContext()
    const navigateTo = useNavigate()

    const removeTransaction = async(id) => {
        try {
            const response = await Api.delete(`transactions/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })

            console.log(response)
            if(response.data.status !== true){
                showToast(response.data.message, 'error')
            } else {
                showToast(response.data.message, 'success')
                navigateTo('/money-manager', {
                    state: {
                        compo: params.type
                    }
                })
            }
        } catch (error) {
            
        }
    }

    return (
        <div className="transaction-page section-page">
            <div className="transaction-page-header">
                <div className="section-page-header">
                    <Link to="/money-manager"><FontAwesomeIcon icon={faArrowLeft} /></Link>
                    <div className="text-center w-100"><h2>Detail Transaction</h2></div>
                    <button type="button" className="btn bg-transparent text-white small btn-sm" onClick={() => removeTransaction(params.id)}>Hapus</button>
                </div>
            </div>
            <div className="section-page-body">
                {params.type === 'expense' ? (
                    <ExpenseDetail id={params.id} />
                ) : (
                    <IncomeDetail id={params.id}  />
                )}
            </div>
            
        </div>
    )
}

export default DetailTransaction