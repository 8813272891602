import { useEffect, useState } from "react";
import Api from "../services/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faArrowLeft, faPencil, faTimes } from "@fortawesome/free-solid-svg-icons";
// import { ToastContainer, toast } from "react-toastify"
// import "react-toastify/dist/ReactToastify.css"
import { useAuthContext } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";

const IncomeDetail = ({ id }) => {
    const { token, showToast } = useAuthContext()
    const [categories, setCategories] = useState([])
    const [formData, setFormData] = useState({
        nominal: '',
        type: 'Income',
        category_id: '',
        payment_type: '',
        trans_date: '',
        notes: '',
        category_name: ''
    })
    const [formErrors, setFormErrors] = useState({
        nominal: '',
        category_id: '',
        payment_type: '',
        trans_date: '',
        notes: '',
        category_name: ''
    });
    const [loading, setLoading] = useState(false)
    const navigateTo = useNavigate()
    const [showCategoriesBox, setShowCategoriesBox] = useState(false) 
    const [manageCategoryBox, setManageCategoryBox] = useState(false)
    const [categoryName, setCategoryName] = useState('')
    const [categoryId, setCategoryId] = useState('')
    const [categoryForm, setCategoryForm] = useState('add')
    const [categoryError, setCategoryError] = useState({})

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value
        });
    };

    const handleChange2 = (e) => {
        const value = e.target.value;
        setFormData({
            ...formData,
            nominal: formatToRupiah(value)
        });
      };

    const handleChange3 = (id, name) => {
        setFormData({
            ...formData,
            category_id: id,
            category_name: name
        });
        setShowCategoriesBox(false)
    };

    const formatToRupiah = (value) => {
        if (!value) return '';
        const cleanedValue = value.toString().replace(/\D/g, '');
        return cleanedValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
      };

    const getCategories = async () => {
        try {
            const response = await Api.get(`categories/income`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            // console.log(response)
            setCategories(response.data.data)
        } catch (error) {
            console.log(error)
        }
    }

    const validasiForm = () => {
        let valid = true
        const errors = {}

        if(!formData.nominal.trim()){
            errors.nominal = 'Nominal tidak boleh kosong'
            valid = false
        }

        if(!formData.category_id){
            errors.category_id = 'Kategori tidak boleh kosong'
            valid = false
        }

        if(!formData.trans_date){
            errors.trans_date = 'Tanggal tidak boleh kosong'
            valid = false
        }

        if(!formData.payment_type){
            errors.payment_type = 'Pembayaran tidak boleh kosong'
            valid = false
        }

        setFormErrors(errors)
        return valid
    }

    const EditTransactionProcess = async (e) => {
        e.preventDefault()
        if(validasiForm()){
            try {
                setLoading(true)
                const payloads = {
                    "nominal": formData.nominal.replaceAll(".",""),
                    "type":"Income",
                    "category_id": formData.category_id,
                    "payment_type": formData.payment_type,
                    "trans_date": formData.trans_date,
                    "notes": formData.notes,
                    "category_name": formData.category_name
                }
                const response = await Api.post(`transactions/${id}`, payloads, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
                // console.log(response)

                if(response.data.status !== true){
                    // toast.error(response.data.message, {
                    //     position: 'top-center'
                    // })
                    showToast(response.data.message, 'error')
                } else {
                    // toast.success(response.data.message, {
                    //     position: 'top-center'
                    // })
                    showToast(response.data.message, 'success')
                    setFormData({
                        nominal: '',
                        type: 'Income',
                        category_id: '',
                        payment_type: '',
                        trans_date: '',
                        notes: '',
                        category_name: ''
                    })
                    navigateTo('/money-manager', {
                        state: {
                            compo: 'income'
                        }
                    })
                }
                
            } catch (error) {
                // toast.error('Terjadi kesalahan pada server', {
                //     position: 'top-center'
                // })
                showToast('Terjadi kesalahan pada server', 'error')
                console.log(error)
            } finally {
                setLoading(false)
            }
        }
    }

    const showCategory= async (id, name) => {
        setCategoryId(id)
        setCategoryName(name)
        setCategoryForm('update')
    }

    const addCategory= async (e) => {
        e.preventDefault()
        let valid = true
        const error = {}
       
        if(categoryName === ''){
            error.name = 'Nama kategori tidak boleh kosong'
            valid = false
        }
        setCategoryError(error)
        // console.log(categoryError)
        if(valid){
            try {
                const payloads = {
                    "name": categoryName,
                    "type": "Income"
                }
    
                if(categoryForm === 'add'){
                    const response = await Api.post(`categories`, payloads, {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    })
                    // console.log(response)

                    if(response.data.status !== true){
                        showToast(response.data.message, 'error')
                    } else {
                        showToast(response.data.message, 'success')
                        setCategoryName('')
                        getCategories()
                    }
                    
                } else if(categoryForm === 'update'){
                    const response = await Api.post(`categories/${categoryId}`, payloads, {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    })
                    // console.log(response)

                    if(response.data.status !== true){
                        showToast(response.data.message, 'error')
                    } else {
                        showToast(response.data.message, 'success')
                        setCategoryName('')
                        getCategories()
                        setCategoryForm('add')
                    }
                    
                }
            } catch (error) {
                showToast('Terjadi kesalahan pada server', 'error')
                console.log(error)
            }
        }
    }

    const deleteCategory= async (id) => {
        try {
            const response = await Api.delete(`categories/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            // console.log(response)

            if(response.data.status !== true){
                showToast(response.data.message, 'error')
            } else {
                getCategories()
            }
            
        } catch (error) {
            showToast('Terjadi kesalahan pada server', 'error')
            console.log(error)
        }
    }

    const cancelUpdate = () => {
        setCategoryForm('add')
        setCategoryName('')
        setCategoryId('')
    }

    const getDetailTransaction = async (id) => {
        try {
            const response = await Api.get(`transactions/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            if(response.data.data.length > 0){
                const d = response.data.data[0]
                setFormData({
                    nominal: formatToRupiah(d.nominal),
                    type: 'Income',
                    category_id: d.category_id,
                    payment_type: d.payment_type,
                    trans_date: d.trans_date,
                    notes: d.notes,
                    category_name: d.category_name
                })
            } else {
                setFormData({})
            }

        } catch (error) {
            console.log(error)
            showToast('Terjadi kesalahan pada server', 'error')
        }
    }

    useEffect(() => {
        getCategories()
        getDetailTransaction(id)
    }, [])

    return (
        <>
            {/* <ToastContainer theme="colored" hideProgressBar={false} closeOnClick  /> */}
            <form className="add-transaction-form" onSubmit={EditTransactionProcess}>
                <div className="form-group">
                    <label>Jumlah</label>
                    <input type="text" inputMode="numeric" className={`form-control shadow-none ${formErrors.nominal ? 'is-invalid': ''}`} name="nominal" value={formData.nominal} onChange={handleChange2}/>
                    {formErrors.nominal && (<div className="invalid-feedback">{formErrors.nominal}</div>) }
                </div>
                <div className="form-group">
                    <div className="d-flex justify-content-between">
                        <label className="m-0">Kategori</label>
                        <button type="button" className="btn bg-transparent btn-sm p-0" onClick={() => setManageCategoryBox(true)}><FontAwesomeIcon icon={faPencil} className="small" /></button>
                    </div>
                    
                    {/* <select className={`form-control shadow-none ${formErrors.category_id ? 'is-invalid': ''}`} name="category_id" value={formData.category_id} onChange={handleChange}>
                        <option value="">Pilih</option>
                        {categories.length > 0 && categories.map((i, idx) => (
                            <option value={i.id} key={idx}>{i.name}</option>
                        ))}
                    </select> */}
                    <input type="text" className={`form-control bg-transparent ${formErrors.category_id ? 'is-invalid': ''}`} onClick={() => setShowCategoriesBox(true)} name="category_name" value={formData.category_name} readOnly />
                    {formErrors.category_id && (<div className="invalid-feedback">{formErrors.category_id}</div>) }
                </div>
                <div className="form-group">
                    <label>Tanggal</label>
                    <input type="date" className={`form-control shadow-none ${formErrors.trans_date ? 'is-invalid': ''}`} name="trans_date" value={formData.trans_date} onChange={handleChange} />
                    {formErrors.trans_date && (<div className="invalid-feedback">{formErrors.trans_date}</div>) }
                </div>
                <div className="form-group">
                    <label>Pembayaran</label>
                    {/* <select className={`form-control shadow-none ${formErrors.payment_type ? 'is-invalid': ''}`} name="payment_type" value={formData.payment_type} onChange={handleChange}>
                        <option value="">Pilih</option>
                        <option value="Tunai">Tunai</option>
                        <option value="Debit">Debit</option>
                        <option value="Dompet Digital">Dompet Digital</option>
                        <option value="Kartu Kredit">Kartu Kredit</option>
                    </select> */}
                    <div className="radio-transaction">
                        <div className="form-check form-check-inline">
                            <input className={`form-check-input ${formErrors.payment_type ? 'is-invalid': ''}`} type="radio" name="payment_type" onChange={handleChange} id="payment_type1" value="Tunai" checked={formData.payment_type == 'Tunai' ? true : false} />
                            <label className="form-check-label" htmlFor="payment_type1">Tunai</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input className={`form-check-input ${formErrors.payment_type ? 'is-invalid': ''}`} type="radio" name="payment_type" onChange={handleChange} id="payment_type2" value="Debit" checked={formData.payment_type == 'Debit' ? true : false} />
                            <label className="form-check-label" htmlFor="payment_type2">Debit</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input className={`form-check-input ${formErrors.payment_type ? 'is-invalid': ''}`} type="radio" name="payment_type" onChange={handleChange} id="payment_type3" value="Dompet Digital" checked={formData.payment_type == 'Dompet Digital' ? true : false} />
                            <label className="form-check-label" htmlFor="payment_type3">Dompet Digital</label>
                        </div>
                        <div className="form-check form-check-inline custom-radio">
                            <input className={`form-check-input ${formErrors.payment_type ? 'is-invalid': ''}`} type="radio" name="payment_type" onChange={handleChange} id="payment_type4" value="Kartu Kredit" checked={formData.payment_type == 'Kartu Kredit' ? true : false} />
                            <label className="form-check-label" htmlFor="payment_type4">Kartu Kredit</label>
                        </div>
                    </div>
                    {formErrors.payment_type && (<div className="text-danger small">{formErrors.payment_type}</div>) }
                </div>
                <div className="form-group">
                    <label>Catatan</label>
                    <input type="text" className={`form-control shadow-none ${formErrors.notes ? 'is-invalid': ''}`} name="notes" placeholder="Optional" value={formData.notes} onChange={handleChange} />
                    {formErrors.notes && (<div className="invalid-feedback">{formErrors.notes}</div>) }
                </div>
                
                <div className="transaction-footer">
                    <div className="wrapper">
                        <button type="submit" className="btn btn-primary btn-block" disabled={loading}>{loading ? (<><FontAwesomeIcon icon={faSpinner} spin /> Loading...</>) : 'Simpan Perubahan' }</button>
                    </div>
                </div>
            </form>

            <div className={`mobile-popup ${showCategoriesBox ? 'open':''}`}>
                <div className="mobile-popup-header">
                    <button type="button" className="btn bg-transparent border-0" onClick={() => setShowCategoriesBox(false)}><FontAwesomeIcon icon={faArrowLeft} /></button>
                    <div className="text-center w-100"><h2>Kategori</h2></div>
                </div>
                <div className="mobile-popup-body">
                    <ul className="category-list">
                        {categories.length > 0 ? (
                            <>
                                {categories.map((i, idx) => (
                                    <li key={idx} onClick={() => handleChange3(i.id, i.name)}>{i.name}</li>
                                ))}
                            </>
                        ) : (
                            <>
                                <div className="alert alert-danger">Tidak ada data</div>
                            </>
                        )}
                        
                    </ul>
                </div>
            </div>

            <div className={`mobile-popup ${manageCategoryBox ? 'open':''}`}>
                <div className="mobile-popup-header">
                    <button type="button" className="btn bg-transparent border-0" onClick={() => setManageCategoryBox(false)}><FontAwesomeIcon icon={faArrowLeft} /></button>
                    <div className="text-center w-100"><h2>Kelola Kategori</h2></div>
                </div>
                <div className="mobile-popup-body">
                    <div className="mb-3">
                        <form onSubmit={addCategory}>
                            <div className="form-group">
                                <label>Nama Kategori</label>
                                <input type="text" className={`form-control ${categoryError.name ? 'is-invalid': ''}`} onChange={(e) => setCategoryName(e.target.value)} value={categoryName}/>
                                {categoryError.name && (<div className="invalid-feedback">{categoryError.name}</div>) }
                            </div>
                            {categoryForm === 'update' && (<><button type="button" className="btn btn-light btn-sm mr-2" onClick={() => cancelUpdate()}>Batal</button></>)}
                            <button type="submit" className="btn btn-success btn-sm">Simpan</button>
                        </form>
                    </div>
                    <strong>List Kategori</strong>
                    <ul className="category-list">
                        {categories.length > 0 ? (
                            <>
                            {categories.map((i, idx) => (
                                <li key={idx} className="d-flex justify-content-between">
                                    <div>
                                        {i.user_id !== 0 ? (<button type="button" className="btn bg-transparent p-0 mr-3 text-danger"  onClick={() => deleteCategory(i.id)}><FontAwesomeIcon icon={faTimes} /></button>) : (<div style={{  display: 'inline-block', width: '28px'}}></div>)}
                                         <span>{i.name}</span> 
                                    </div>
                                    <div>
                                        {i.user_id !== 0 && (<button type="button" className="btn bg-transparent p-0 mr-3 text-primary"  onClick={() => showCategory(i.id, i.name)}><FontAwesomeIcon icon={faPencil} /></button>)}
                                    </div>
                                </li>
                            ))}
                            </>
                        ) : (
                            <>
                                <div className="alert alert-danger my-2">Tidak ada data</div>
                            </>
                        )}
                        
                    </ul>
                </div>
            </div>
        </>
    )
}

export default IncomeDetail